@import '~styles/colors.scss';
@import '~styles/controls.scss';

.nodeNameEditInput > .error {
  color: $errorColor;
}

input.nodeNameEditInput {
  margin: 0;
  padding: 0;
  width: 1ch;
}

.hiddenCharacter {
  opacity: 0;
}