@import '~styles/sizes';
@import '~styles/colors';

.nodeDetailsPane {
	width: 33vw;

	.nodeName {
		font-weight: bold;
		font-size: $larger-font-size;
	}

	.fieldLabel {
		font-weight: bold;
	}
}

.nodeDetailsPane > .body {
	margin: 0.25em;
}
