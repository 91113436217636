@import '~styles/colors';

.activeStackFrame {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0.25em;

	.body {
		margin: 0.25em;
	}
}

.activeStackFrame:hover {
	cursor: pointer;
}

.activeStackFrame.selected {
	background-color: $lighterControlColor;
}

.activeStackFrame > .name {
	display: flex;
	flex-grow: 1;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.activeStackFrame {
		border-bottom: 0.1em dotted $veryLightGrey;
	}

	.activeStackFrame:hover {
		background-color: lighten($veryLightGrey, 10%);
		cursor: pointer;
	}

	.activeStackFrame > .name {
		color: $inProgressColorLight;
	}
}

@media (prefers-color-scheme: dark) {
	.activeStackFrame {
		border-bottom: 0.1em dotted $veryLightGreyDark;
	}

	.activeStackFrame:hover {
		background-color: lighten($veryLightGreyDark, 10%);
		cursor: pointer;
	}

	.activeStackFrame > .name {
		color: $inProgressColorDark;
	}
}
