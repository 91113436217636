@import '~styles/sizes';

.accountFormName {
	font-weight: bold;
	vertical-align: top;
	min-width: 8em;
	display: inline-block;
	margin-bottom: 0.25em;
	padding-top: 0.25em;
}

.accountFieldGrouping {
	margin-top: 0.25em;
}

.accountFormSection {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.accountFormButton {
	padding-left: 7px;

	@media screen and (max-width: $break-small) {
		width: 100%;
	}
}

.accountChangePasswordField {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
}

.changePasswordFieldGrouping {
	padding-top: 1em;

	@media screen and (max-width: $break-small) {
		padding-top: 0;
	}
}
