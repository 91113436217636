@import '~styles/sizes';
@import '~styles/colors';

.userTopBar {
	width: 100%;
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	opacity: 0.97;
	font-size: $larger-font-size;
	display: flex;
	flex-direction: column;
	padding: 10px 1.25em 5px;
	@media screen and (max-width: $break-small) {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}
	box-shadow: -5px -5px 15px $secondLayerDark;
	z-index: 3;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.userTopBar {
		background-image: linear-gradient(to bottom right, $secondLayerLight, darken($secondLayerLight, 2%));
	}

	.userTopBarBrand {
		display: inline-block;
	}

	.userTopBarBrandDark {
		display: none;
	}

	.userTopBarBrandTitle {
		color: $link;
	}

	.userTopBarBrandTitle:hover {
		color: lighten($link, 25);
	}
}

@media (prefers-color-scheme: dark) {
	.userTopBar {
		background-image: linear-gradient(to bottom right, darken($secondLayerDark, 2%), $secondLayerDark);
	}

	.userTopBarBrand {
		display: none;
	}

	.userTopBarBrandDark {
		display: inline-block;
	}

	.userTopBarBrandTitle {
		color: $linkDark;
	}

	.userTopBarBrandTitle:hover {
		color: lighten($linkDark, 25);
	}
}

.userTopBarBrand,
.userTopBarBrandDark {
	height: 1.25em;
	vertical-align: text-bottom;
}

.userTopBarBrandTitle {
	padding-left: 0.5em;
	font-weight: bold;
}

.userTopBarBrandLink:hover {
	text-decoration: none;
}

.userTopBarHeader {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.userTopBarRight > a:hover {
	text-decoration: none;
}

.userTopBarRight {
	margin-right: 3px;

	a {
		padding-left: 6px;
	}
}

.bottomSearchBar > .textSearchInput {
	width: 100%;
}

.leftSearchBar {
	display: inline-block;
	@media screen and (max-width: $break-small) {
		display: none;
	}
}

.bottomSearchBar {
	display: none;
	@media screen and (max-width: $break-small) {
		display: block;
	}
}
