@import '~styles/sizes';
@import '~styles/colors';

.activeStackPanel {
	overflow-y: auto;

	height: 100%;
	display: flex;
	flex-direction: column;
	min-width: 200px;
}

.activeStackPanel > .body > .none {
	padding-left: 0.25em;
	font-weight: bold;
}
