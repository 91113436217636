@import '~styles/sizes';
@import '~styles/colors';

.slidingPanelView {
	display: flex;
	flex-direction: row;

	.left {
		max-width: 300px;
	}

	.center {
		flex-grow: 1;
	}

	.right {
	}

	.splitViewBody {
		height: calc(100vh - 150px);
		//max-height: calc(100vh - 150px);
		overflow-y: auto;
		scrollbar-gutter: stable;
		scrollbar-track-color: grey;
	}
}

.slidingPanelView > * > .header {
	font-weight: bold;
	font-size: $largest-font-size;
	margin: 0.25em;
	text-align: center;
	position: -webkit-sticky;
	position: sticky;
	top: 00px;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.slidingPanelView > div > .header {
		background-color: $veryLightGrey;
	}
}

@media (prefers-color-scheme: dark) {
	.slidingPanelView > div > .header {
		background-color: $veryLightGreyDark;
	}
}
