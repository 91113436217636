$lighterControlColor: rgba(224, 208, 193, 0.25);
$lightControlColor: rgba(224, 208, 193, 0.5);
$mediumControlColor: rgba(209, 190, 171, 0.5);
$darkerControlColor: rgba(194, 173, 153, 0.5);

$primaryColor: #331a00;
$activeColor: #6f3900;

$successColor: darkolivegreen;
$errorColor: darkred;

$backgroundColorLight: white;
$backgroundColorDark: #37393e;

$secondLayerLight: #c78a55;
$secondLayerDark: #1f2123;

$veryLightGrey: rgb(236, 236, 236);
$veryLightGreyDark: rgb(78, 78, 78);

$lightGrey: rgb(229, 229, 229);
$lightGreyDark: rgb(75, 75, 75);

$darkGrey: darkgrey;

$darkNeutral: rgb(60, 60, 60);

$textColorLight: black;
$textColorDark: rgba(255, 255, 255, 0.75);
$emphaticTextColor: #000086;

$inProgressColorLight: rgb(171, 97, 7);
$resolvedColorLight: #004d00;
$resolvedStrikethroughColorLight: rgba(50, 180, 50, 0.4);
$rejectedColorLight: darkred;
$rejectedStrikethroughColorLight: rgba(180, 50, 50, 0.2);

$inProgressColorDark: #f3af6a;
$resolvedColorDark: #72b772;
$resolvedStrikethroughColorDark: rgba(72, 255, 72, 0.4);
$rejectedColorDark: #ff7777;
$rejectedStrikethroughColorDark: rgba(194, 90, 90, 0.4);

$link: #331a00;
$linkDark: #d27d46;
