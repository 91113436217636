@import '~styles/colors';
@import '~styles/sizes';

.treeControls {
	font-size: 90%;
	display: flex;
	padding-top: 0.5em;
	padding-bottom: 0.25em;

	.filterModeLabel {
		font-weight: bold;
		font-size: $small-font-size;
	}
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.treeControls {
		background: $backgroundColorLight;
	}
}

@media (prefers-color-scheme: dark) {
	.treeControls {
		background: $backgroundColorDark;
	}
}

.treeControls.sticky {
	position: sticky;
	position: -webkit-sticky;
	z-index: 2;
	top: 0px;
	@media screen and (max-width: $break-small) {
		top: 67px;
	}
}
