.nodeNameInner {
	word-break: break-word;
}

.taskNameInner {
	font-weight: bold;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.highlight {
		background-color: rgb(253, 255, 50);
	}
}

@media (prefers-color-scheme: dark) {
	.highlight {
		background-color: rgba(253, 255, 50, 0.25);
	}
}

.nodeName > .icon {
	margin-right: 0.25em;
	opacity: 0.5;
}
