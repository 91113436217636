@import '~styles/colors.scss';

.treeNode > .treeNodeLeft > .expandCollapse > .expandIcon {
	display: inline-block;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.treeNode > .treeNodeLeft > .expandCollapse > .expandIcon {
		color: $textColorLight;
	}
	.treeNode > .treeNodeLeft > .expandCollapse > .expandIconSolid {
		color: $textColorLight;
	}
	.treeNodeRight > .nodeHeader:hover > .nodeName > .nodeNameInner {
		background-color: $veryLightGrey;
	}
	.treeNode.selected > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		background-color: $veryLightGrey;
	}
}

@media (prefers-color-scheme: dark) {
	.treeNode > .treeNodeLeft > .expandCollapse > .expandIcon {
		color: $textColorDark;
	}
	.treeNode > .treeNodeLeft > .expandCollapse > .expandIconSolid {
		color: $textColorDark;
	}
	.treeNodeRight > .nodeHeader:hover > .nodeName > .nodeNameInner {
		background-color: $veryLightGreyDark;
	}
	.treeNode.selected > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		background-color: $lightGreyDark;
	}
}

.treeNode > .treeNodeLeft > .expandCollapse > .expandIconSolid {
	display: none;
}

.treeNode > .treeNodeLeft > .expandCollapse:hover > .expandIcon {
	display: none;
}

.treeNode > .treeNodeLeft > .expandCollapse:hover > .expandIconSolid {
	display: inline-block;
}

.treeNode > .treeNodeLeft > .expandCollapse {
	border: none;
	background: transparent;
	padding: 0;
}

.treeNode > .treeNodeLeft {
	width: 1em;
	padding-right: 0.5em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tree > .treeNode {
	margin-left: 0.25em;
}

.treeNode {
	padding-top: 0.2em;
	display: flex;
	align-items: flex-start;
	border: none;
}

.treeNode > .treeNodeRight > .nodeHeader > .nodeName {
	display: inline-block;
	position: relative;
	width: 100%;
}

.treeNode > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
	display: inline-block;
	padding: 1px;
}

.treeNode > .treeNodeRight > .nodeHeader > .error {
	color: $errorColor;
}

.treeNode.selected > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
	border-radius: 0.25em;
}

.treeNode.collapsed > .treeNodeRight > .nodeChildren {
	display: none;
}

.treeNode.deleted {
	display: none;
}

.treeNode.resolved > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
	text-decoration: line-through;
}

.treeNode.rejected > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
	text-decoration: line-through;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.treeNode.inProgress > .treeNodeLeft > .expandCollapse {
		color: $inProgressColorLight;
	}

	.treeNode.resolved > .treeNodeLeft > .expandCollapse {
		color: $resolvedColorLight;
	}

	.treeNode.rejected > .treeNodeLeft > .expandCollapse {
		color: $rejectedColorLight;
	}

	.treeNode.rejected > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		color: $rejectedColorLight;
		text-decoration-color: $rejectedStrikethroughColorLight;
	}

	.treeNode.resolved > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		color: $resolvedColorLight;
		text-decoration-color: $resolvedStrikethroughColorLight;
	}

	.treeNode.inProgress > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		color: $inProgressColorLight;
	}
}

@media (prefers-color-scheme: dark) {
	.treeNode.inProgress > .treeNodeLeft > .expandCollapse {
		color: $inProgressColorDark;
	}

	.treeNode.resolved > .treeNodeLeft > .expandCollapse {
		color: $resolvedColorDark;
	}

	.treeNode.rejected > .treeNodeLeft > .expandCollapse {
		color: $rejectedColorDark;
	}

	.treeNode.rejected > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		color: $rejectedColorDark;
		text-decoration-color: $rejectedStrikethroughColorDark;
	}

	.treeNode.resolved > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		color: $resolvedColorDark;
		text-decoration-color: $resolvedStrikethroughColorDark;
	}

	.treeNode.inProgress > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		color: $inProgressColorDark;
	}
}

.treeNode.selected > .treeNodeRight > .nodeHeader {
	outline: none;
}

.treeNodeRight > .nodeHeader:hover > .nodeName > .nodeNameInner {
	border-radius: 0.25em;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.treeNode.hoveredBelow {
		border-bottom: 1px dotted $textColorLight;
	}

	.treeNode.hoveredAbove {
		border-top: 1px dotted $textColorLight;
	}

	.treeNode.hoveredCenter > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		border: 1px dotted $textColorLight;
	}
}

@media (prefers-color-scheme: dark) {
	.treeNode.hoveredBelow {
		border-bottom: 1px dotted $textColorDark;
	}

	.treeNode.hoveredAbove {
		border-top: 1px dotted $textColorDark;
	}

	.treeNode.hoveredCenter > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
		border: 1px dotted $textColorDark;
	}
}

.treeNode.hoveredCenter > .treeNodeRight > .nodeHeader > .nodeName > .nodeNameInner {
	padding: 0;
	border-radius: 0.25em;
}

.treeNodeRight {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	position: relative;
}

.nodeChildren:before {
	position: absolute;
	top: 1.5em;
	left: calc(-0.75em - 1px);
	bottom: 0;
	display: block;
	content: '';
	border-left: 2px dotted rgba(147, 147, 147, 0.7);
}

.treeNode.selected > .treeNodeRight > .nodeChildren:before {
	border-left: 2px dotted darkgrey;
}

.topDragTarget {
	margin-top: -5px;
	width: 100%;
	height: 10px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.centerDragTarget {
	width: 100%;
	position: absolute;
	bottom: 7px;
	height: 10px;
	left: 0;
	z-index: 1;
}

.bottomDragTarget {
	margin-bottom: -5px;
	width: 100%;
	height: 10px;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
}
