@import '~styles/colors.scss';

.nodeDropPlaceholder > .placeholderRight > .error {
	color: $errorColor;
}

.nodeDropPlaceholder {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	padding-right: 0.25em;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
}

.placeholderRight {
	display: flex;
	flex-direction: column;
	position: relative;
	flex-grow: 1;
}

.placeholderLeft {
	width: 1em;
	padding-right: 0.5em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.placeholderRight > .nodeName {
	display: inline-block;
}

.placeholderActionButton {
	font-size: 13px;
	padding: 0 0.25em 0 0;
	border: none;
	background: transparent;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.nodeDropPlaceholder.inProgress > .placeholderRight > .nodeName {
		color: $inProgressColorLight;
	}

	.nodeDropPlaceholder.resolved > .placeholderRight > .nodeName {
		color: $resolvedColorLight;
		text-decoration-color: $resolvedStrikethroughColorLight;
	}

	.nodeDropPlaceholder.rejected > .placeholderRight > .nodeName {
		color: $rejectedColorLight;
		text-decoration-color: $rejectedStrikethroughColorLight;
	}

	.nodeDropPlaceholder.inProgress > .placeholderLeft {
		color: $inProgressColorLight;
	}

	.nodeDropPlaceholder.resolved > .placeholderLeft {
		color: $resolvedColorLight;
		text-decoration-color: $resolvedStrikethroughColorLight;
	}

	.nodeDropPlaceholder.rejected > .placeholderLeft {
		color: $rejectedColorLight;
		text-decoration-color: $rejectedStrikethroughColorLight;
	}
}

@media (prefers-color-scheme: dark) {
	.nodeDropPlaceholder.inProgress > .placeholderRight > .nodeName {
		color: $inProgressColorDark;
	}

	.nodeDropPlaceholder.resolved > .placeholderRight > .nodeName {
		color: $resolvedColorDark;
		text-decoration-color: $resolvedStrikethroughColorDark;
	}

	.nodeDropPlaceholder.rejected > .placeholderRight > .nodeName {
		color: $rejectedColorDark;
		text-decoration-color: $rejectedStrikethroughColorDark;
	}

	.nodeDropPlaceholder.inProgress > .placeholderLeft {
		color: $inProgressColorDark;
	}

	.nodeDropPlaceholder.resolved > .placeholderLeft {
		color: $resolvedColorDark;
		text-decoration-color: $resolvedStrikethroughColorDark;
	}

	.nodeDropPlaceholder.rejected > .placeholderLeft {
		color: $rejectedColorDark;
		text-decoration-color: $rejectedStrikethroughColorDark;
	}
}

.nodeDropPlaceholder.resolved > .placeholderRight > .nodeName {
	text-decoration: line-through;
}

.nodeDropPlaceholder.rejected > .placeholderRight > .nodeName {
	text-decoration: line-through;
}
