@import '~styles/colors';

.standardButton {
	color: $primaryColor;
	font-weight: bold;
	border: none;
	border-radius: 5px;
	background-color: $lightControlColor;
	padding: 0.25em;
}

.standardInput {
	background-color: $lightControlColor;
	border-radius: 5px;
	-webkit-appearance: none;
	padding: 0 0.25em;
	border: 1px solid transparent;
}

.standardInput::placeholder {
	color: black;
	opacity: 0.5;
}

.standardInput:focus {
	outline: none;
	border: 1px solid lightgray;
}

.standardButton:hover {
	color: $activeColor;
	background-color: $mediumControlColor;
}

.standardButton:disabled,
.standardButton:disabled:hover {
	color: grey;
	background-color: rgb(241, 240, 240);
}
