@import '~styles/safeAreaInsets';
@import '~styles/sizes';

.mainContent {
	padding-top: 0;
	min-height: calc(100vh - 9em);
	@include supports-safe-area-insets {
		padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right);
	}
}

.mainContent > div > .nav > li > a {
	padding: 3px 8px !important;
}

.innerFooter {
	padding-top: 1em;
	padding-bottom: 0.25em;
	text-align: center;
	font-size: $smallest-font-size;
}

.dynamicallyPaddedContainer {
	padding: 0.25em;
}