// Responsive design mobile max-width threshold
$break-small: 500px;

// Standard font sizes
$smallest-font-size: 13px;
$smaller-font-size: 14px;
$small-font-size: 15px;
$medium-font-size: 16px;
$large-font-size: 17px;
$larger-font-size: 18px;
$largest-font-size: 19px;

// Matches the size of fa-lg
$fa-large-font-size: 20px;
