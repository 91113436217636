@import '~styles/colors.scss';
@import '~styles/controls.scss';

input.newNodeInput {
	margin-top: 0.25em;
	width: 1ch;
	text-overflow: ellipsis;
}

.newNodeInput > .error {
	color: $errorColor;
}

.newNodeOutlineO {
	width: 1em;
	display: inline-block;
}

.hiddenCharacter {
	opacity: 0;
}