@import '~styles/colors.scss';
@import '~styles/sizes.scss';

.treeNodeControls {
	font-size: 15px;
	display: flex;
	flex-direction: column;
	margin-top: 0.4em;
}

.treeNodeControls > .armableButtons {
	display: flex;
	flex-direction: row;
}

.treeNodeControls > .armableButtons > * {
	margin-right: 0.4em;
}

.tncButton {
	padding: 0 0.25em 0 0;
	border: none;
	background: transparent;
}

.expandButtonBar {
	border: none;
	background: transparent;
	outline: none;
}

.tncCCButton {
	font-size: 10px;
	@media screen and (max-width: $break-small) {
		font-size: 16px;
		padding-left: 0.25em;
		padding-right: 0.25em;
	}
}

.tncButton:hover {
	text-decoration: underline;
}

.treeNodeControls > .error {
	color: $errorColor;
}

@media (prefers-color-scheme: dark) {
	.treeNodeControls > .armableButtons > select {
		background-color: rgb(49, 49, 49);
		color: lighten($linkDark, 0.3);
		font-weight: bold;
		border: none;
	}
}
