@import '~styles/colors';
@import '~styles/sizes';

.comment {
	background-color: $lighterControlColor;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
}

.comment > .header {
	font-size: $smaller-font-size;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	display: flex;
	flex-direction: row;
	background-color: $darkerControlColor;
	padding: 3px;
}

.comment > .body {
	padding: 3px;
}
