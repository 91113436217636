@import '~styles/colors.scss';

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	body {
		background-color: $backgroundColorLight;
		color: $textColorLight;
	}

	a:hover,
	button:hover {
		color: #6f3900;
	}

	a,
	button {
		color: $link;
	}
}

@media (prefers-color-scheme: dark) {
	body {
		background-color: $backgroundColorDark;
		color: $textColorDark;
	}

	a:hover,
	button:hover {
		color: lighten($linkDark, 25);
	}

	a,
	button {
		color: $linkDark;
	}
}

a:link {
	text-decoration: none;
}
