@import '~styles/colors';

.nWayToggleButton {
	margin-left: 2px;
	margin-right: 2px;
	padding: 0 0.25em;
	border-radius: 3px;
	float: left;
	background-color: $lighterControlColor;
	outline: none !important;
	border: 1px solid $lighterControlColor;
}


@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.nWayToggleButton {
		color: $textColorLight
	}
}

@media (prefers-color-scheme: dark) {
	.nWayToggleButton {
		color: $textColorDark
	}
}


.nWayToggleButton:hover {
	background-color: $mediumControlColor;
}

.selectedNWayToggleButton {
	font-weight: bold;
	border: 1px solid $darkerControlColor;
	background-color: $darkerControlColor;
}

.nWayToggleButton.selectedNWayToggleButton:hover {
	background-color: $darkerControlColor;
}