@import '~styles/colors';

.commonInput {
	border-style: none;
	background-color: $lighterControlColor;
	border-radius: 3px;
	white-space: nowrap;
	outline-width: 0;
	clear: right;
}

.commonInput:disabled {
	color: rgba(0, 0, 0, 0.5);
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.commonInput::placeholder {
		color: rgba(51, 51, 51, 0.333);
	}
}

@media (prefers-color-scheme: dark) {
	.commonInput::placeholder {
		color: rgba(255, 255, 255, 0.66);
	}
}