@import '~styles/sizes';
@import '~styles/colors';

.createAccountFormField {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	margin-right: 5px;
	padding-left: 7px;
	padding-right: 7px;
	@media screen and (max-width: $break-small) {
		width: calc(100% - 14px);
	}
}

.createAccountFormFieldInError {
	background-color: rgb(255, 233, 236);
}

.createAccountErrorLabel {
	color: $errorColor;
	padding-top: 0.5em;
	font-weight: bold;
}

.createAccountButton {
	margin-top: 0.25em;
}
