.armableButtonContainer {
	display: flex;
	flex-direction: row;
}

.confirmButton {
	color: rgba(50, 150, 50, 1);
	&:hover {
		color: rgba(100, 200, 100, 1);
	}
}
.disarmButton {
	color: rgba(150, 50, 50, 1);
	&:hover {
		color: rgba(200, 100, 100, 1);
	}
}
