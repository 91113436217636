@import '~styles/colors';
@import '~styles/sizes';

.slateInput {
	min-height: 3em;
	max-width: 100%;
	background: none;
	border-style: none;
	border-radius: 3px;
}
.slateEditor {
	background: $lighterControlColor;
	max-width: 100%;
	border-radius: 3px;

	.display {
		flex-grow: 1;
	}

	.editLinks {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		align-content: flex-end;
		align-items: center;
		font-size: $smallest-font-size;

		margin-right: 4px;

		> button {
			margin-left: 0.5em;
		}
	}
}

.slateEditor.displayOnly.editable {
	display: flex;
	align-items: flex-end;
	flex-direction: row;
	min-height: 1em;
}


.slateEditor.displayOnly.editable.withBorder {
	border: 1px dashed $darkGrey;
	padding: 3px;
}

@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
	.slateEditor {
		.displayOnly {
			background: $backgroundColorLight;
		}
	}
}

@media (prefers-color-scheme: dark) {
	.slateEditor {
		.displayOnly {
			background: $backgroundColorDark;
		}
	}
}

.slateToolbar {
	background-color: $mediumControlColor;
	border-radius: 3px 3px 0 0;

	button {
		background-color: transparent;
	}

	.toolActive {
		color: $activeColor;
		background-color: $darkerControlColor;
	}

	button.standardButton.toolActive {
		border-radius: 0;
	}

	button.standardButton.toolActive:first-of-type {
		border-radius: 3px 0 0;
	}
}
